import { RootState } from "@/lib/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { fromUnixTime, isAfter } from "date-fns";
import { decode } from "jsonwebtoken";
import jwt_decode, { JwtPayload } from "jwt-decode";

import { setAccessToken } from "../reducers/user";
import { AppPayload } from "../types";

type ParamsType = {
  params: { [key: string]: string | number | any };
};

export function passParams({ params }: ParamsType) {
  const queryStrings: any = {};
  Object.keys(params).map((arg, _index) => {
    queryStrings[arg] = params[arg];
  });
  return encodeURIComponent(JSON.stringify(queryStrings));
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_SERVER_URL + "/rest/dbo",
  prepareHeaders: async (headers, { getState }) => {
    const accessToken = (getState() as RootState).user.accessToken;
    if (accessToken) {
      const payload: AppPayload = decode(accessToken) as AppPayload;
      headers.set("x-hub2-token", payload.token!);
    }
    headers.set("Content-type", "application/json");
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const accessToken = (api.getState() as RootState).user.accessToken;
  let decoded: JwtPayload | string = "";
  try {
    decoded = jwt_decode(accessToken);
  } catch (e) {}
  const exp = fromUnixTime(decoded.exp!);
  const now = new Date();

  if (isAfter(exp, now) || !accessToken || !decoded || !decoded.exp) {
    let result = await baseQuery(args, api, extraOptions);
    return result;
  } else {
    const tokenRefresh = await fetch(`/api/refresh_token`, {
      method: "post",
      credentials: "include",
    }).then((res) => res.json());

    if (tokenRefresh.accessToken) {
      api.dispatch(setAccessToken({ accessToken: tokenRefresh.accessToken }));
    }

    let result = await baseQuery(args, api, extraOptions);
    return result;
  }
};

export enum ObjectTypeAPI {
  Assets = "Assets",
  Visit = "Visit",
  VisitSheet = "VisitSheet",
  Buildings = "Buildings",
  Clients = "Clients",
  Contracts = "Contracts",
  Risks = "Risks",
  Users = "Users",
  Dashboards = "Dashboards",
  Generic = "Generic",
  Client = "Client",
  Subsystem = "Subsystem",
  ContractSubsystem = "ContractSubsystem",
  Building = "Building",
  Floorplan = "Floorplan",
  BoxDevice = "BoxDevice",
  DeviceFieldValue = "DeviceFieldValue",
  HelpBlock = "HelpBlock",
}
const {
  Clients,
  Dashboards,
  Users,
  Risks,
  Buildings,
  Contracts,
  Assets,
  Visit,
  VisitSheet,
  Generic,
} = ObjectTypeAPI;

//SERVER TYPES IS DUMPED FROM <BACKEND>/export-types.php -- re-run periodically
const serverTypes = [
  "AppLink",
  "AppInternalState",
  "Asset",
  "AssetStatus",
  "AssetSub",
  "AssetGroup",
  "CategoryAsset",
  "BillingUsage",
  "BoxDevice",
  "ControlRequest",
  "BoxInstance",
  "BoxDeviceUidRemap",
  "Building",
  "BuildingGroup",
  "ContractBuilding",
  "UserBuildingRestriction",
  "BuildingApprovalGroup",
  "BuildingFloor",
  "BuildingThirdPartyApprovalUser",
  "BuildingZone",
  "Client",
  "ClientAssignment",
  "ClientRelationship",
  "ClientReverseRelationship",
  "ClientRelationshipBuilding",
  "ClientRole",
  "UserClientRole",
  "ClientRolePrivilege",
  "BaseRole",
  "BaseRolePrivilege",
  "ColumnsOrder",
  "Competence",
  "Contract",
  "ContractConfig",
  "Currency",
  "Dashboard",
  "DeviceClass",
  "DeviceClassField",
  "DeviceClassIcon",
  "DeviceFieldValue",
  "Floorplan",
  "HelpBlock",
  "ItemApproval",
  "JobPlan",
  "JobPlanSchedule",
  "JobPlanStatus",
  "Log",
  "WallDashboard",
  "Matterport",
  "Message",
  "MessageThread",
  "MessageThreadUser",
  "MessageStatus",
  "MpTaskTemplate",
  "MpProjectTemplate",
  "Notification",
  "NotificationContent",
  "NotificationContentFooter",
  "NotificationClassification",
  "NotificationRecipient",
  "PasswordReset",
  "Permit",
  "DayPermit",
  "PermitHistory",
  "EmergencyPermit",
  "DayEmergencyPermit",
  "Privilege",
  "QRFreeLink",
  "RestRequestCache",
  "RestToken",
  "LoginEvent",
  "Sector",
  "Status",
  "Subsystem",
  "ContractSubsystem",
  "SystemDefaults",
  "AssetTask",
  "User",
  "UserLocationReport",
  "Zone",
  "AsbestosEntry",
  "AsbestosPhoto",
  "AsbestosAttachment",
  "AsbestosDrawing",
  "AsbestosQuoteLink",
  "AsbestosViewing",
  "AsbestosForm",
  "AsbestosStatus",
  "AsbestosPresence",
  "AsbestosSampleType",
  "AsbestosSurveyType",
  "AsbestosEntryRecommendation",
  "AsbestosActionTime",
  "AsbestosRiskBand",
  "AsbestosAssessor",
  "AsbestosYear",
  "AuditCategory",
  "AuditItem",
  "AuditReviewItem",
  "AuditSheet",
  "AuthUser",
  "AvailabilityAsset",
  "AvailabilityCategory",
  "AvailabilityHistory",
  "AvailabilityResource",
  "Alarm",
  "AlarmStatus",
  "AlarmCategory",
  "AnalysisReport",
  "AnalysisReportCredential",
  "Comment",
  "CriticalAlarmExecutionRecord",
  "CriticalAlarmExecutionPlan",
  "CriticalAlarmEngineResult",
  "DayMetrics",
  "DemandPeriodBreakdown",
  "EnergySummaryCache",
  "IOTInstance",
  "ItemStatusChangeRequest",
  "MachineProtection",
  "MeteredUtility",
  "TariffStart",
  "UtilityTariff",
  "UtilityUsage",
  "UtilityTimeBreakdown",
  "UtilityTimeBreakdownOverride",
  "ScheduledControlEvent",
  "SensorResultCache",
  "DeviceClassCountMapCache",
  "CacheableFunction",
  "CachedResult",
  "CachedSeenParameter",
  "CallRecord",
  "ReportDatapoint",
  "BuildingDataValue",
  "TaskTemplate",
  "WorkOrder",
  "WorkType",
  "WorkStatus",
  "WorkCategory",
  "WorkOrderPriority",
  "WorkOrderEvent",
  "Attachment",
  "ComplianceAsset",
  "ComplianceVisit",
  "ComplianceVisitSheet",
  "Compliment",
  "ComplimentDepartment",
  "ContractcomplianceDocument",
  "ContractcomplianceFile",
  "ContractcomplianceNote",
  "ContractcomplianceRequirement",
  "ContractcomplianceType",
  "Dailyengineering",
  "DailyengineeringEntry",
  "DailyengineeringFile",
  "DlogsDoc",
  "DlogsCategory",
  "DlogItem",
  "DlogItemVisit",
  "DlogsNotifiedEmail",
  "Document",
  "DocumentCategory",
  "DocumentFolder",
  "DocumentsVote",
  "Company",
  "PermitsAuthorityToAcces",
  "PermitsAuthorityToAccesHistory",
  "PermitsControlMeasure",
  "PermitsRam",
  "PermitsRamNote",
  "PermitsRamsDictionaryService",
  "PermitsReissued",
  "PermitsReport",
  "PermitsSetting",
  "PermitsTemplate",
  "PermitsThirdPartyApprovalQueue",
  "LogBookCategory",
  "LogBookClientCategory",
  "LogBookTask",
  "LogBookClientTask",
  "LogBookLog",
  "LogBookVisit",
  "Failure",
  "DocumentsFinance",
  "Hazard",
  "HazardPhoto",
  "HazardCategory",
  "HazardType",
  "HazardCause",
  "HazardsEventsHistory",
  "HazardsEventsNote",
  "HnsAudit",
  "HnsFile",
  "HnsItem",
  "HnsProject",
  "InductionCategory",
  "InductionHistory",
  "InductionInvitation",
  "inductionPending",
  "InductionQuestion",
  "InductionTake",
  "Category",
  "DocumentsIntranet",
  "DocumentsIntranetVote",
  "KpiArea",
  "KpiAttachment",
  "KpiItem",
  "KpiSheet",
  "KpiTemplate",
  "KpiToleranceBand",
  "KpiServiceLevel",
  "LifecycleAsset",
  "LifecycleCategory",
  "LifecycleItem",
  "MailingMessage",
  "MobileauditAudit",
  "MobileauditFile",
  "MobileauditItem",
  "MobileauditProject",
  "MpTask",
  "MpProject",
  "MpTaskProgress",
  "PpmimportQueue",
  "Quote",
  "QuoteUser",
  "Visit",
  "VisitParent",
  "VisitSheet",
  "Labour",
  "Material",
  "QuotesAttachment",
  "QuotesContractor",
  "QuotesHistory",
  "QuotesLabour",
  "QuotesMaterial",
  "QuotesRequest",
  "Risk",
  "RiskAttachment",
  "RiskCategory",
  "RiskHistory",
  "RiskNote",
  "RiskVote",
  "RotaAsset",
  "RotaShift",
  "RotaVisit",
  "Shiftlog",
  "ShiftlogEntry",
  "ShiftlogFile",
  "SOPDocument",
  "SOPDocumentState",
  "SOPRole",
  "SOPTemplate",
  "SOPPriority",
  "SOPProcedureType",
  "SOPProcedureStatus",
  "SOPCategory",
  "SOPStatus",
  "SOPNotification",
  "DocumentsTechlib",
  "DocumentsTechlibVote",
  "Induction",
  "TmHistory",
  "TmPersonnel",
  "TmPersonnelBuilding",
  "TmPersonnelFile",
  "TmPosition",
  "TmPositionProgramme",
  "TmProgramme",
  "TmProgrammeBuilding",
  "TmProgrammeCategory",
  "TmProgrammeSubCategory",
  "TmSuppressed",
  "Translation",
  "UserCertificate",
  "WalkaroundAsset",
  "WalkaroundCategory",
  "WalkaroundCode",
  "WalkaroundField",
  "WalkaroundFieldCondition",
  "WalkaroundShift",
  "WarrantyDocument",
  "WarrantyDocumentEvent",
  "WarrantyCategory",
  "DocumentsWarrantyVote",
  "BmsNetwork",
  "DeviceValueToLabelMap",
  "BmsGif",
  "BmsGifGlobal",
  "CategoryAssetLevel2",
  "CategoryAssetLevel3",
  "CategoryAssetLevel4",
  "CategoryAssetTag",
  "Sfg",
  "SfgServiceGroup",
  "BoxDeviceStatus",
  "BuildingLocation",
  "SubmeterCategory",
  "BoxMeter",
  "VideoFeed",
];

export const omniApi = createApi({
  tagTypes: [
    Clients,
    Dashboards,
    Users,
    Risks,
    Buildings,
    Contracts,
    Assets,
    Visit,
    VisitSheet,
    Generic,
  ].concat(serverTypes),
  reducerPath: "/api/omni",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    keepAlive: build.query<any, any>({
      query: () => ({
        url: `/`,
        method: "get",
      }),
    }),
  }),
});

export const { useKeepAliveQuery } = omniApi;
